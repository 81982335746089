.SignIn {
  .Form {
    margin-top: 5rem;
  }

  h1, p {
    text-align: center;
  }

  h1 {
    margin-bottom: 0.5rem;
  }

  p {
    margin: 0 0 4rem;
    color: #444;
  }

  &__container {
    max-width: 550px;
    padding: 0 1.5rem;
    margin: 4rem auto 2rem;
  }
}