.ButtonGroup {
  display: flex;
  justify-content: flex-end;
  position: relative;

  &__link, &__toggle {
    text-decoration: none;
    border: 2px solid #eee;
    background-color: #fff;
    font-family: inherit;
    font-size: 1rem;
    padding: 0.5rem 1.5rem;
    font-weight: 600;
    transition: 150ms linear all;

    @media (hover: hover) {
      &:hover {
        border-color: #ddd;
        cursor: pointer;
        box-shadow: 0 0 0 0.2rem rgba(#000, 0.05);
      }
    }
  }

  &__link {
    border-top-left-radius: 3rem;
    border-bottom-left-radius: 3rem;
    border-right: 1px solid #eee;
  }

  &__toggle {
    border-top-right-radius: 3rem;
    border-bottom-right-radius: 3rem;
    border-left: 1px solid #eee;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    & svg {
      height: 1.5rem;
      width: 1.5rem;
    }
  }

  &__links {
    background-color: #fff;
    border: 2px solid #ddd;
    border-radius: 0.2rem;
    position: absolute;
    top: calc(100% + 0.5rem);
    right: 0;
  }

  &__button {
    width: 100%;
    border: none;
    background: none;
    font-family: inherit;
    font-size: inherit;
    text-align: left;
    display: block;
    padding: 1rem 1.5rem;
    text-decoration: none;
    color: inherit;
    transition: 150ms linear all;

    &:hover {
      cursor: pointer;
      background-color: rgba(#000, 0.03);
    }

    &--important {
      color: #f22d2d;
    }
  }
}