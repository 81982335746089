.Home {
  &__container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 1.5rem;
  }

  &__licence {
    text-align: center;
    padding: 1rem;
    background-color: #ccefee;
  }

  &__error {
    margin: 1.5rem;
    color: #666;
  }
}