body {
  margin: 0;
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root {
  height: 100%;
}

.Form {
  &__row {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }

    label {
      font-weight: 600;
      margin-bottom: 0.5rem;
      color: #444;
    }

    input[type=text], input[type=password] {
      padding: 1rem;
      font-size: inherit;
      font-family: inherit;
      border: 2px solid #aaa;
      border-radius: 0.2rem;
      transition: 150ms linear box-shadow;

      &:focus {
        outline: none;
        box-shadow: 0 0 0 0.25rem rgba(#000000, 0.1);
      }
    }

    button {
      font-size: 1.1rem;
      font-family: inherit;
      background-color: #1f1f2a;
      color: #fff;
      font-weight: 600;
      border-radius: 0.2rem;
      border: none;
      padding: 1rem;
      transition: 150ms linear box-shadow;

      &:hover {
        cursor: pointer;
        box-shadow: 0 0 0 0.25rem rgba(#000000, 0.1);
      }

      &:disabled {
        opacity: 0.4;
      }
    }

    &--buttons {
      margin-top: 2rem;
    }

    &--links {
      margin-top: 1rem;

      a {
        display: inline-block;
        text-decoration-color: #666;
        color: #666;
        transition: 150ms linear all;

        &:hover {
          color: #444;
        }
      }
    }
  }
}

table {
  width: calc(100% + 3rem);
  border: none;
  margin-left: -1.5rem;


  thead {
    th {
      text-align: left;
      padding: 0.75rem 1rem;
      color: #666;
      font-weight: 600;
      border-bottom: 2px solid #eee;
    }
  }

  tbody {
    tr:nth-child(2n) {
      td {
        background-color: #f9f9f9;
      }
    }

    td {
      padding: 1.5rem 1rem;
    }
  }
}

.Table {
  &__column {
    &--right {
      text-align: right;
    }
  }

  &__name {
    font-weight: 700;
    font-size: 1.1rem;
  }

  &__purchased {
    font-size: 0.9rem;
    color: #444;
    margin-top: 0.1rem;
  }

  &__button {
    border-radius: 3rem;
    border: 2px solid #eee;
    background-color: #fff;
    font-family: inherit;
    font-size: 1rem;
    padding: 0.5rem 1.5rem;
    font-weight: 600;
    transition: 150ms linear all;
    margin-right: 0.5rem;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      border-color: #ddd;
      cursor: pointer;
      box-shadow: 0 0 0 0.2rem rgba(#000, 0.05);
    }
  }

  &__colour {
    display: flex;
    align-items: center;
    font-weight: 600;
  }

  &__palette {
    display: inline-block;
    height: 1rem;
    min-height: 1rem;
    width: 1rem;
    min-width: 1rem;
    background-color: #006fff;
    margin-right: 0.5rem;
    border-radius: 50%;
  }
}

a {
  color: #333;
}

p {
  line-height: 1.5;
  color: #666;
}

b {
  color: #222;
}